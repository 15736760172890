import React from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import TemplateColumn, { TemplateColumnWithSidebarContainer } from '../../layouts/TemplateColumn';
import { PageHeader, PageSection, PageSubSection, RelatedContent, CrossLink, IndicationPageIntro, AnchorLinks, SecondaryCallout, PrimaryClaim, EndpointDisplay, ComparisonTable, TextBlock, ReferencesBlock, NCCNMultitumorCallout, ForestPlot } from '../../components';
import MSI_DMMR from '../../components/StudyDesign/__study-design/msi-dmmr'
import { StaticImage } from 'gatsby-plugin-image';
import { jobCodes } from '../../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: `Advanced MSI-H or dMMR Solid Tumors Clinical Trial Results - HCP`,
    keywords: `msi h dmmr pembrolizumab, msi h tumor, msi h keytruda`,
    description: `Health care professionals may find clinical trial results in patients with unresectable or metastatic MSI-H or dMMR solid tumors.`,
    schemaJsonLD: [
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/efficacy/msi-h-dmmr/","@type":"MedicalWebPage","name":"Study Design for KEYNOTE⁠-⁠158, KEYNOTE⁠-⁠164, and KEYNOTE⁠-⁠051","description":"The efficacy of KEYTRUDA was investigated in 504 patients with MSI⁠-⁠H or dMMR cancers enrolled in three multicenter, non-randomized, open-label, multi-cohort trials: KEYNOTE⁠-⁠164 (NCT02460198), KEYNOTE⁠-⁠158 (NCT02628067), and KEYNOTE⁠-⁠051 (NCT02332668). All trials excluded patients with autoimmune disease or a medical condition that required immunosuppression. Regardless of histology, MSI or MMR tumor status was determined using polymerase chain reaction (PCR; local or central) or immunohistochemistry (IHC; local or central), respectively."}`,
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/efficacy/msi-h-dmmr/","@type":"MedicalAudience","audienceType":"health care professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`
    ]
}

//from /src/util/data/indications.js
const indicationId = 22;
const jobCode = jobCodes[22].jobCode;

const relatedContent = [
    {
        section: 'Resources',
        link: {
            url: '/resources/mechanism-of-action/',
            label: 'Mechanism of Action'
        }
    },
    {
        section: 'Resources',
        link: {
            url: 'https://www.merckaccessprogram-keytruda.com/',
            label: 'Access & Reimbursement Support'
        }
    }
];

const crossLinkContent = {
    sections: [
        {
            heading: "Efficacy for Other Indications",
            items: [
                { text: 'MSI&#8288;-&#8288;H/dMMR CRC', url: '/efficacy/msi-h-dmmr-colorectal-cancer/' },
            ]
        },
        {
            heading: "Related Information",
            items: [
                { text: 'Selected Adverse Reactions', url: '/safety/adverse-reactions/msi-h-dmmr/' },
                { text: 'Dosing', url: '/dosing/options/' },
                { text: 'MSI&#8288;-&#8288;H Testing', url: '/biomarker-testing/msi-mmr/' },
            ]
        },
    ],
    interestLinks: {
        heading: "You Might Be Interested In",
        items: [
            { eyebrow: 'Resources', text: 'Mechanism of Action', url: '/resources/mechanism-of-action/' },
        ],
    }
};

const anchorLinkModelData = [
    { label: 'Clinical Findings from KEYNOTE&#8288;-&#8288;158, KEYNOTE&#8288;-&#8288;164, and KEYNOTE&#8288;-&#8288;051' }, 
    { label: 'Study Design for KEYNOTE&#8288;-&#8288;158, KEYNOTE&#8288;-&#8288;164, and KEYNOTE&#8288;-&#8288;051' }, 
    { label: 'Frequency of MSI&#8288;-&#8288;H/dMMR' },
]

const DORtableDefinitions = "CI = confidence interval; GEJ = gastroesophageal junction; NOS = not otherwise specified."

const pageReferences = [
    {
        label: "1.",
        text: "Le DT, Durham JN, Smith KN, et al. Mismatch repair deficiency predicts response of solid tumors to PD&#8288;-&#8288;1 blockade. <i>Science.</i> 2017;357(6349):409–413."
    }
]

const Page = ({ location }) => {

    return (
        <DefaultLayout indicationId={indicationId} jobCode={jobCode} pageMeta={pageMeta} location={location}>
            <div data-template-name="template-e">

                {/* Begin Intro Column */}
                <TemplateColumn id="template-e-intro" maxWidth={824}>
                    <PageHeader title="Clinical Trial Results" />
                    <PageSection bgColor="white">
                        <IndicationPageIntro indicationId={indicationId} />
                        <AnchorLinks items={anchorLinkModelData} />
                    </PageSection>
                    <PageSection bgColor="gradient">
                        <PrimaryClaim theme="light">FDA approved since 2017: KEYTRUDA is the first treatment of its kind indicated to treat all advanced MSI&#8288;-&#8288;H/dMMR solid tumors</PrimaryClaim>
                    </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}

                <TemplateColumnWithSidebarContainer>
                    {/* Begin Main Column */}
                    <TemplateColumn id="template-e-main">
                        <PageSection bgColor="cloud" title={anchorLinkModelData[0].label}>
                            <SecondaryCallout 
                                title="Efficacy studied in 30 different MSI&#8288;-&#8288;H/dMMR cancers in 504 patients" 
                                alt=""
                            />
                            <PageSubSection title="Objective Response Rate and Duration of Response">
                                <EndpointDisplay title="Objective Response Rate (ORR)" limitation="Efficacy studied in 30 different MSI&#8288;-&#8288;H/dMMR cancers in 504<sup>a</sup> patients" cards={
                                    [
                                        {
                                            dataType: 'ORR',
                                            label: 'KEYTRUDA',
                                            rate: '33.3%',
                                            ci: '95% CI<sup>b</sup>, 29.2–37.6',
                                            n: '168/504',
                                            completeResponseRate: '10.3%',
                                            partialResponseRate: '23.0%',
                                        }
                                    ]
                                }
                                footnotes={[
                                    {
                                        label: 'a.',
                                        text: 'Median follow-up time of 20.1 months (range 0.1 to 71.4 months)',
                                    },
                                    {
                                        label: 'b.',
                                        text: 'Of the 7 pediatric patients from KEYNOTE&#8288;-&#8288;051, 1 patient had a radiographic complete response after initial growth of their tumor but is not reflected in the results.',
                                    },
                                ]}
                                />
                                <ComparisonTable title="Duration of Response (DOR)" data={[
                                        [
                                            {
                                                legendTitle: 'KEYTRUDA',
                                            },
                                        ],
                                        [
                                            {
                                                label: 'Median DOR',
                                                dataPoint: '63.2 months',
                                                dataSubPoint: '(range: 1.9+ to 63.9+ months)',

                                            },
                                        ],
                                        [
                                            {
                                                label: 'Patients with responses lasting ≥12 months',
                                                dataPoint: '77%'
                                            },
                                        ],
                                        [
                                            {
                                                label: 'Patients with responses lasting ≥36 months',
                                                dataPoint: '39%'
                                            },
                                        ],
                                    ]}
                                    footnotes={[
                                        {
                                            label: '+',
                                            text: 'Denotes ongoing response.'
                                        }
                                    ]}
                                />
                            </PageSubSection>

                            <PageSubSection title="Objective Response Rate by Tumor Type">
                                <EndpointDisplay title="Objective Response Rate (ORR): Colorectal Cancer (CRC)" limitation="Efficacy studied in colorectal cancers in 124 patients" cards={
                                    [
                                        {
                                            dataType: 'ORR',
                                            label: 'KEYTRUDA',
                                            rate: '34%',
                                            ci: '95% CI, 26%–43%',
                                            n: '42/124',
                                        }
                                    ]
                                }
                                />
                                <ComparisonTable title="DOR Detail" data={[
                                        [
                                            {
                                                legendTitle: 'KEYTRUDA',
                                            },
                                        ],
                                        [
                                            {
                                                label: 'Ongoing duration of response',
                                                dataPoint: '(range: 4.4 to 58.5+ months)',

                                            },
                                        ],
                                    ]}
                                    footnotes={[
                                        {
                                            label: '+',
                                            text: 'Denotes ongoing response.'
                                        }
                                    ]}
                                />
                                <EndpointDisplay title="Objective Response Rate (ORR): Non-CRC" limitation="Efficacy studied in non-colorectal cancers in 380 patients" cards={
                                    [
                                        {
                                            dataType: 'ORR',
                                            label: 'KEYTRUDA',
                                            rate: '33%',
                                            ci: '95% CI, 28%–38%',
                                            n: '126/380',
                                        }
                                    ]
                                }
                                />
                                <ComparisonTable title="DOR Detail"
                                    data={[
                                        [
                                            {
                                                legendTitle: 'KEYTRUDA',
                                            },
                                        ],
                                        [
                                            {
                                                label: 'Ongoing duration of response',
                                                dataPoint: '(range: 1.9+ to 63.9+ months)',

                                            },
                                        ],
                                    ]}
                                    footnotes={[
                                        {
                                            label: '+',
                                            text: 'Denotes ongoing response.'
                                        }
                                    ]}
                                />
                                <TextBlock definitions={DORtableDefinitions}>
                                    <h4>Efficacy studied across a range of MSI‑H or dMMR cancers (n=504):</h4>
                                    <ul>
                                        <li>Adrenocortical</li>
                                        <li>Anal</li>
                                        <li>Appendiceal adenocarcinoma, NOS</li>
                                        <li>Biliary</li>
                                        <li>Bladder</li>
                                        <li>Brain</li>
                                        <li>Breast</li>
                                        <li>Carcinoma of unknown origin</li>
                                        <li>Cervical</li>
                                        <li>Colorectal</li>
                                        <li>Endometrial</li>
                                        <li>Gastric or GEJ</li>
                                        <li>Hepatocellular</li>
                                        <li>Head and Neck Squamous Cell Carcinoma</li>
                                        <li>Mesothelioma</li>
                                        <li>Nasopharyngeal</li>
                                        <li>Neuroendocrine</li>
                                        <li>Ovarian</li>
                                        <li>Pancreatic</li>
                                        <li>Prostate</li>
                                        <li>Renal Cell</li>
                                        <li>Retroperitoneal</li>
                                        <li>Salivary</li>
                                        <li>Sarcoma</li>
                                        <li>Small Cell Lung</li>
                                        <li>Small Intestine</li>
                                        <li>Testicular</li>
                                        <li>Thyroid</li>
                                        <li>Vaginal</li>
                                        <li>Vulvar</li>
                                    </ul>
                                </TextBlock>
                            </PageSubSection>

                            <PageSubSection title={anchorLinkModelData[1].label}>
                                <MSI_DMMR />
                            </PageSubSection>
                        </PageSection>

                        <PageSection bgColor="white" title={anchorLinkModelData[2].label}>
                            <ForestPlot 
                                title="MSI&#8288;-&#8288;H/dMMR Occurs in a Variety of Cancers<sup>1</sup>" 
                                image={() => <StaticImage src="../../assets/msi-forest-plot-v-2.png" placeholder="blurred" alt="MSI-H/dMMR Occurs in a Variety of Cancers" />} 
                            />
                            <TextBlock definitions="MSI&#8288;-&#8288;H = microsatellite instability-high; dMMR = mismatch repair deficient; EGJ = esophagogastric junction; NSCLC = non–small cell lung cancer.">
                                <h5>Supporting Data Permissions</h5>
                                <p>Readers may view, browse, and/or download material for temporary copying purposes only, provided these uses are for noncommercial personal purposes. Except as provided by law, this material may not be further reproduced, distributed, transmitted, modified, adapted, performed, displayed, published, or sold in whole or in part, without prior written permission from the publisher.</p>
                                <p>Republished with permission of American Association for the Advancement of Science, from Mismatch repair deficiency predicts response of solid tumors to PD&#8288;-&#8288;1 blockade, Le, 357, 2017; permission conveyed through Copyright Clearance Center, Inc.</p>
                            </TextBlock>
                        </PageSection>
                    </TemplateColumn>

                    {/* End Main Column */}
                    <TemplateColumn id="template-e-sidebar">
                        <PageSection bgColor="mist">
                            <CrossLink {...crossLinkContent} />
                        </PageSection>
                    </TemplateColumn>

                </TemplateColumnWithSidebarContainer>

                <TemplateColumn id="template-e-secondary">
                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent} />
                    </PageSection>

                    <PageSection bgColor="mist">
                        <ReferencesBlock references={pageReferences} />
                    </PageSection>
                </TemplateColumn>
            </div>
        </DefaultLayout>
    );
};

export default Page;

import React from 'react';
import ComponentWrapper from '../../../components/layout/ComponentWrapper';
import { StudyCopyBlock } from '../index';

const MSI_DMMR = () => (
    
    <ComponentWrapper>
        <StudyCopyBlock 
            description={`
                <p>The efficacy of KEYTRUDA was investigated in 504 patients with MSI&#8288;-&#8288;H or dMMR cancers enrolled in three multicenter, non-randomized, open-label, multi-cohort trials: KEYNOTE&#8288;-&#8288;164 (NCT02460198), KEYNOTE&#8288;-&#8288;158 (NCT02628067), and KEYNOTE&#8288;-&#8288;051 (NCT02332668). All trials excluded patients with autoimmune disease or a medical condition that required immunosuppression. Regardless of histology, MSI or MMR tumor status was determined using polymerase chain reaction (PCR; local or central) or immunohistochemistry (IHC; local or central), respectively.</p>
                <ul>
                    <li>KEYNOTE&#8288;-&#8288;164 enrolled 124 patients with advanced MSI&#8288;-&#8288;H or dMMR colorectal cancer (CRC) that progressed following treatment with fluoropyrimidine and either oxaliplatin or irinotecan +/- anti&#8288;-&#8288;VEGF/EGFR mAb-based therapy.</li>
                    <li>KEYNOTE&#8288;-&#8288;158 enrolled 373 patients with advanced MSI&#8288;-&#8288;H or dMMR non-colorectal cancers (non-CRC) who had disease progression following prior therapy. Patients were either prospectively enrolled with MSI&#8288;-&#8288;H/dMMR tumors (Cohort K) or retrospectively identified in one of 10 solid tumor cohorts (Cohorts A-J).</li>
                    <li>KEYNOTE&#8288;-&#8288;051 enrolled 7 pediatric patients with MSI&#8288;-&#8288;H or dMMR cancers.</li>
                </ul>
                <p>Adult patients received KEYTRUDA 200&nbsp;mg every 3 weeks (pediatric patients received 2&nbsp;mg/kg every 3 weeks) until unacceptable toxicity, disease progression, or a maximum of 24 months.</p>
            `}  
        />

        <StudyCopyBlock 
            title="Efficacy Measures"
            description={`
                <p>In KEYNOTE&#8288;-&#8288;164 and KEYNOTE&#8288;-&#8288;158, assessment of tumor status was performed every 9 weeks through the first year, then every 12 weeks thereafter. In KEYNOTE&#8288;-&#8288;051, assessment of tumor status was performed every 8 weeks for 24 weeks, and then every 12 weeks thereafter. The major efficacy outcome measures were ORR and DOR as assessed by BICR according to RECIST v1.1 (modified to follow a maximum of 10 target lesions and a maximum of 5 target lesions per organ in KEYNOTE&#8288;-&#8288;158) and as assessed by the investigator according to RECIST v1.1 in KEYNOTE&#8288;-&#8288;051.</p>    
            `} 
        />

        <StudyCopyBlock 
            title="Patient Population Details"
            description={`
                <p>In KEYNOTE&#8288;-&#8288;164 and KEYNOTE&#8288;-&#8288;158, the study population characteristics were median age of 60 years, 36% age 65 or older; 44% male; 78% White, 14% Asian, 4% American Indian or Alaska Native, and 3% Black; and 45% ECOG PS of 0 and 55% ECOG PS of 1. Ninety-two percent of patients had
                metastatic disease and 4% had locally advanced, unresectable disease. Thirty-seven percent of patients received one prior line of therapy and 61% received two or more prior lines of therapy.</p>
                <p>In KEYNOTE&#8288;-&#8288;051, the study population characteristics were median age of 11 years (range: 3 to 16); 71% female; 86% White and 14% Asian; and 57% had a Lansky/Karnofsky Score of 100.</p>
                <p>Seventy-one percent of patients had Stage IV and 14% had Stage III disease. Fifty-seven percent of patients received one prior line of therapy and 29% received two prior lines of therapy.</p>
                <p>Discordant results were observed between local MSI&#8288;-&#8288;H or dMMR tests and central testing among patients enrolled in Cohort K of KEYNOTE&#8288;-&#8288;158. Among 104 tumor samples that were MSI&#8288;-&#8288;H or dMMR by local testing and also tested using the FoundationOne®CDx (F1CDx) test, 59 (56.7%) were MSI&#8288;-&#8288;H and 45 (43.3%) were not MSI&#8288;-&#8288;H. Among 169 tumor samples that were MSI&#8288;-&#8288;H or dMMR by local testing and also tested using the VENTANA MMR RxDx Panel, 105 (62.1%) were dMMR and 64 (37.9%) were pMMR.</p>
            `} 
            definitions={`
                BICR = blinded independent central review; ECOG PS = Eastern Cooperative Oncology Group performance status; EGFR = epidermal growth factor receptor; mAb = monoclonal antibody; MMR = mismatch repair; MSI = microsatellite instability; pMMR = proficient mismatch repair; RECIST v1.1 = Response Evaluation Criteria In Solid Tumors v1.1; VEGF = vascular endothelial growth factor.
            `}
        />
    </ComponentWrapper>
);

export default MSI_DMMR;
